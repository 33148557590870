'use strict';

const {
    chooseBonusProducts
} = require('../product/base');
let pageHelpers = require('../helpers/pageHelpers');

const productTileClass = '.product-tile';

const unselectSize = ($container) => {
    $container.find('.js-plp-size-button').removeClass('selected');
    var $addToCartBtn = $container.find('.js-add-to-cart-tile');
    $addToCartBtn.attr('data-ready-to-order', 'false');
    $addToCartBtn.attr('data-variationUrl', '');
};

const clickSize = (e) => {
    var $button = $(e.currentTarget);
    var variationUrl = $button.attr('data-variationUrl');
    var $productContainer;
    if ($button.hasClass('js-wl-btn')) {
        $productContainer = $button.closest('.tile-container').find(productTileClass);
        unselectSize($button.closest('.tile-container'));
    } else {
        $productContainer = $button.closest(productTileClass);
        unselectSize($productContainer);
    }
    $button.addClass('selected');
    var $addToCartBtn;
    if ($button.hasClass('js-wl-btn')) {
        $addToCartBtn = $productContainer.parent().find('.js-add-to-cart-tile');
        $addToCartBtn.prop('disabled', false);
    } else {
        $addToCartBtn = $productContainer.find('.js-add-to-cart-tile');
    }
    var isStoreOnly = $button.attr('data-store-only');
    if (isStoreOnly === 'true') {
        $addToCartBtn.addClass('d-none').removeClass('d-block');
    } else {
        $addToCartBtn.removeClass('d-none').addClass('d-block');
    }
    $addToCartBtn.attr('data-ready-to-order', 'true');
    $addToCartBtn.attr('data-variationUrl', variationUrl);
};

const clickAddToCartHandler = (e) => {
    var $button = $(e.currentTarget);
    var isReadyToOrder = $button.attr('data-ready-to-order');
    var isOrderable = $button.attr('data-is-orderable');
    var $productContainer;
    if ($button.hasClass('js-wl-btn')) {
        $productContainer = $button.parent().find(productTileClass);
    } else {
        $productContainer = $button.closest(productTileClass);
    }
    if (isOrderable === 'false' || (pageHelpers.isMobileDevice() && isReadyToOrder === 'false')) {
        window.location.href = $button.attr('data-productUrl');
    } else {
        if (isReadyToOrder === 'true') {
            addProductToCart($productContainer);
        } else {
            var $sizeSection;
            if ($button.hasClass('js-wl-btn')) {
                $sizeSection = $productContainer.parent().find('.size-overlay-section');
            } else {
                $sizeSection = $productContainer.find('.size-overlay-section');
            }
            $sizeSection.addClass('error-to-selection');
            setTimeout(function () {
                $sizeSection.removeClass('error-to-selection');
            }, 3000);
        }
    }
};

const addProductToCart = ($container) => {
    var $button;
    if ($container.hasClass('js-wl-btn')) {
        $button = $container.parent().find('.js-add-to-cart-tile');
    } else {
        $button = $container.find('.js-add-to-cart-tile');
    }
    var variationUrl = $button.attr('data-variationUrl');
    var addToCartUrl = $button.attr('data-addToCartUrl');
    $.spinner().start();
    if ($button.attr('data-isSpecialOrder') === 'true') {
        window.location.href = $button.attr('data-productUrl');
    } else if (!variationUrl) {
        var productId = $container.attr('data-pid');
        addToCart(addToCartUrl, productId);
    } else {
        addVariationToCart(variationUrl, addToCartUrl, $container);
    }
};

const addVariationToCart = (variationUrl, addToCartUrl, container) => {
    var url = new URL(variationUrl);
    url.searchParams.set('quantity', 1);
    $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
            var productId = data.product.id;
            addToCart(addToCartUrl, productId, container);
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

const addToCart = (addToCartUrl, productId, container) => {
    var form = {
        pid: productId,
        quantity: 1
    };

    $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: form,
        success: function (response) {
            handlePostCartAdd(response);
            $('body').trigger('product:afterAddToCart', response);
            miniCartReportingUrl(response.reportingURL);
            $.spinner().stop();
            openMiniCart();
            if (container) {
                unselectSize(container);
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

// Opens minicart on for seconds set value in sitePreference
function openMiniCart() {
    var minicartTime = $('.minicart-total').attr('data-minicartshowtime');
    if (!minicartTime) return;
    $('.minicart').trigger('open');
    setTimeout(function () {
        $('.minicart .popover').removeClass('show');
    }, minicartTime);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function (response) {
                $('.minicart .klaviyo').html(response);
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

function initEvents() {
    $(document).on('click', '.js-plp-size-button', clickSize);
    $(document).on('click', '.js-add-to-cart-tile', clickAddToCartHandler);
}

module.exports = {
    initEvents
};
