/**
 * Animates a ripple effect when a button is clicked
 * @param {Object} $button - jQuery object of the button element
 * @param {Object} e - event object
 */
function animateRipple($button, e, afterAnimationFinish) {
    let offset = $button.offset();
    let x = e.pageX - offset.left;
    let y = e.pageY - offset.top;

    let $ripple = $('<span>').addClass('ripple').css({
        top: y + 'px',
        left: x + 'px'
    });

    $button.append($ripple);

    setTimeout(function () {
        $ripple.remove();
        if (afterAnimationFinish) {
            afterAnimationFinish();
        }
    }, 1500);
}

module.exports = function () {
    $('body').on('effect:Ripple', function (e, response) {
        var $btn = response.button;
        var event = response.event;
        animateRipple($btn, event, response.afterAnimationFinish);
    });
};
