import Swiper, {Navigation, Pagination, Autoplay} from 'swiper/core';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

const headerBannerConfigs = {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    speed: 3000,
    loopFillGroupWithBlank: true,
    autoplay: {
        delay: 3000 // This value will be overwritten on the bannerSlider.isml file. Just set default value to enable it.
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
};

const swiperSearchNoResultsPage = {
    slidesPerView: 4,
    slidesPerGroup: 1,
    loop: false,
    spaceBetween: 16,
    navigation: {
        nextEl: '.swiper-products-btn-next',
        prevEl: '.swiper-products-btn-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 4,
        }
    }
};

const swiperSecondCartPage = {
    slidesPerView: 4,
    slidesPerGroup: 1,
    loop: false,
    spaceBetween: 16,
    navigation: {
        nextEl: '.swiper-products-second-btn-next',
        prevEl: '.swiper-products-second-btn-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 4,
        }
    }
};

const swiperSearchRecommendedPDP = {
    slidesPerView: 4,
    slidesPerGroup: 1,
    loop: false,
    spaceBetween: 16,
    navigation: {
        nextEl: '.swiper-products-rec-btn-next',
        prevEl: '.swiper-products-rec-btn-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 4,
        }
    }
};

const swiperSearchLastViewedPDP = {
    slidesPerView: 4,
    slidesPerGroup: 1,
    loop: false,
    spaceBetween: 16,
    navigation: {
        nextEl: '.swiper-products-last-btn-next',
        prevEl: '.swiper-products-last-btn-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 4,
        }
    }
};

const swiperSearchNoResultsExploring = {
    slidesPerView: 'auto',
    spaceBetween: 16,
    loop: false
};

const heroBannerConfigsHP = {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    loopFillGroupWithBlank: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets'
    }
};

const tileBannerConfigsHP = {
    slidesPerView: 1.5,
    centeredSlides: true,
    slidesPerGroup: 1,
    loop: false,
    loopFillGroupWithBlank: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        576: {
            slidesPerView: 2.5,
            centeredSlides: false
        },
        992: {
            slidesPerView: 4.5,
            centeredSlides: false
        }
    }
};

const mainBannerConfigsHP = {
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    loopFillGroupWithBlank: false,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    }
};

const customRecomConfigsHP = {
    slidesPerView: 2,
    spaceBetween: 20,
    slidesPerGroup: 1,
    loopFillGroupWithBlank: false,
    navigation: {
        nextEl: '.custom-recom-next',
        prevEl: '.custom-recom-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    },
    breakpoints: {
        // when window width is <= 499px
        768: {
            slidesPerView: 3,
        }
    }
};

const cartTileCarousel = {
    slidesPerView: 4.5,
    slidesPerGroup: 2,
    loop: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 4,
        }
    }
};

const swiperRecommendationsPlp = {
    slidesPerView: 4,
    slidesPerGroup: 1,
    loop: false,
    spaceBetween: 16,
    navigation: {
        nextEl: '.swiper-products-btn-next',
        prevEl: '.swiper-products-btn-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 2,
        },
        480: {
            slidesPerView: 2,
        },
        640: {
            slidesPerView: 3,
        }
    }
};

// init header banner Swiper:
if ($('.js-slider-banner').length) {
    var sliderBanner = new Swiper(".js-slider-banner", headerBannerConfigs); // eslint-disable-line
}

if ($('.swiper-products').length) {
    var swiperProducts = new Swiper('.swiper-products', swiperSearchNoResultsPage); // eslint-disable-line
}

if ($('.swiper-products-second').length) {
    var swiperProducts = new Swiper('.swiper-products-second', swiperSecondCartPage); // eslint-disable-line
}

if ($('.swiper-products-rec').length) {
    var swiperRecommended = new Swiper('.swiper-products-rec', swiperSearchRecommendedPDP); // eslint-disable-line
}

if ($('.swiper-products-last').length) {
    var swiperLastViewed = new Swiper('.swiper-products-last', swiperSearchLastViewedPDP); // eslint-disable-line
}

if ($('.swiper-exploring').length) {
    var swiperExploring = new Swiper('.swiper-exploring', swiperSearchNoResultsExploring); // eslint-disable-line
}

// init header banner Swiper:
if ($('.custom-recom-next').length) {
    var sliderBanner = new Swiper(".js-slider-custom-recommendations", customRecomConfigsHP); // eslint-disable-line
}

// init homepage hero Swiper:
if ($('.js-swiper-hero-initialize').length) {
    $('.js-swiper-hero-initialize').parent().addClass('swiper-slide slider-item');
    if ($('.js-swiper-hero-initialize').closest('.swiper-wrapper').length) {
        $('.js-hide-component').removeClass('d-none');
        $('.js-show-component').removeClass('d-block').addClass('d-none');
    }
    var heroHomePage = new Swiper(".js-slider-homepage-component", heroBannerConfigsHP); // eslint-disable-line
}

var carouselTiles = $('.js-carousel-tile');
for (var i = 0; i < carouselTiles.length; i++) {
    var currentCarouselTile = carouselTiles[i];
    var swiper = $(currentCarouselTile).find('.js-swiper-tiles-initialize');
    
    if (swiper.length && !$(swiper).closest('.content-tile__no-carousel').length) {
        $(swiper).parent().addClass('swiper-slide slider-item');
        $(currentCarouselTile).find('.js-slider-homepage-tiles').addClass('slider' + i);
        var tileHomePage = new Swiper('.slider' + i, tileBannerConfigsHP); // eslint-disable-line
    }
}

// init homepage main Swiper:
if ($('.js-swiper-main-initialize').length) {
    $('.js-swiper-main-initialize').parent().addClass('swiper-slide slider-item');

    mainBannerConfigsHP.pagination.renderBullet = function (index, className) {
        var title = $('input[name=paginationTitle]')[index+1].value;
        var subTitle = $('input[name=paginationSubTitle]')[index+1].value;
        $(':root').css('--colorHover', $('input[name=paginationColorHover]').val());

        return `<div class="${className} js-custom-pagination ">
            <h3 class="text-uppercase d-lg-block d-none"> ${title} </h3>
            <p class="d-lg-block d-none"> ${subTitle} </p>
            </div>`;
    };

    var mainHomePage = new Swiper(".js-main-slider-component", mainBannerConfigsHP); // eslint-disable-line
}

// init cart tile Swiper:
if ($('.js-swiper-cart-tile').length) {
    var sliderTileCarousel = new Swiper(".js-swiper-cart-tile", cartTileCarousel); // eslint-disable-line
}

// init swiper with recommendations on plp
if ($('.swiper-plp').length) {
    var swiperProducts = new Swiper(".swiper-plp", swiperRecommendationsPlp); // eslint-disable-line
}

$(document).scroll(function () {
    if ($(window).innerWidth() > 991) {
        // Content Slides Layout
        var elements = $('.js-swiper-hero-initialize');
        // Main Banner Slider
        var bgElements = $('.js-bg-image1');

        for (var i = 0; i < elements.length; i++) {
            var currentElement = elements[i];
            var position = $(currentElement).offset();
            $(currentElement).css('background-position-y', ($(document).scrollTop() - position.top) + 'px');
        }

        for (var j = 0; j < bgElements.length; j++) {
            var currentBgElement = bgElements[j];
            var bgPosition = $(currentBgElement).offset();
            $(currentBgElement).css('background-position-y', ($(document).scrollTop() - bgPosition.top) + 'px');
        }
    }
});
