'use strict';

/* global Promise braintree $ */

var applePayErrorMessages = require('../constants/errorMessages');

var braintreeApplePaySDK = require('./braintreeApplePaySDK');
var braintreeGeneral = require('../../braintreeGeneral');

// Global variables
// Is Froud tools enabled
var isFtEnabled;
var btClientInstancePromise;
var apInstance;
var amount;
var billingContact;
var shippingContact;
var config = window.dw.applepay;
var action = config.action;
/** Latest Apple Pay request data to use to create a session. */
var request;
/** Request object that gets updated with new data from server responses. */
var updatedRequest;
// set updatedRequest to request
updatedRequest = Object.assign({}, request);


/**
 * Init braintree Apple Pay model
 * @param {Promise} braintreeClientInstancePromise Braintreee client instance promise
 * @param {boolean} isFraudToolsEnabled Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, isFraudToolsEnabled) {
    braintreeApplePaySDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    isFtEnabled = isFraudToolsEnabled;
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    var response;

    if (isFtEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: applePayErrorMessages.FRAUD_DATA_CELLECTION_ISNOT_ENABLED
        });
    }

    return response;
}

/**
 * Preparing Apple Pay payment
 * @param {Object} ApplePaySession Apple Pay session
 * @returns {Promise} Promise with 'canMakePayments' boolean value
 */
function initApplePay(ApplePaySession) {
    return braintreeApplePaySDK.createApplePayPayment()
        .then(function (applePayInstance) {
            apInstance = applePayInstance;

            return ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier);
        });
}

/**
 * Merges a payment request with Braintree defaults to return an {external:ApplePayPaymentRequest}
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Object} Payment request object
 */
function createPaymentDataRequest(applePayConfigs) {
    return braintreeApplePaySDK.createPaymentDataRequest(apInstance, applePayConfigs, amount, billingContact, shippingContact);
}

/**
 * Validates merchant website, as required by ApplePaySession before payment can be authorized
 * @param {Object} sessionEvent Apple Pay session event
 * @param {Object} applePayConfigs Apple Pay config object
 * @returns {Promise} Promise with merchant session
 */
function onValidateMerchant(sessionEvent, applePayConfigs) {
    return braintreeApplePaySDK.performValidation(apInstance, sessionEvent, applePayConfigs);
}

/**
 * Tokenizes an Apple Pay payment
 * @param {Object} sessionEvent Apple Pay session event
 * @returns {Promise} Promise with tokenized payload
 */
function onPaymentAuthorized(sessionEvent) {
    return braintreeApplePaySDK.tokenize(apInstance, sessionEvent);
}

/**
 * Update order amount
 * @param {Object} applePayConfigs Apple Pay config object
 */
function updateApplePayAmount(applePayConfigs) {
    $.ajax({
        url: applePayConfigs.getOrderInfoUrl,
        async: false,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            amount = data.amount;
        },
        error: function () {
            window.location.reload();
        }
    });
}

/**
 * Update order addresses
 * @param {Object} applePayConfigs Apple Pay config object
 */
function updateApplePayAddresses(applePayConfigs) {
    $.ajax({
        url: applePayConfigs.getOrderInfoUrl,
        async: false,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            var shippingAddress = data.shippingAddress;

            if (shippingAddress) {
                shippingContact = {
                    familyName: shippingAddress.lastName,
                    givenName: shippingAddress.firstName,
                    phoneNumber: shippingAddress.phone,
                    addressLines: [
                        shippingAddress.line1,
                        shippingAddress.line2
                    ],
                    countryCode: shippingAddress.countryCode,
                    postalCode: shippingAddress.postalCode,
                    locality: shippingAddress.state
                };
            }
        },
        error: function () {
            window.location.reload();
        }
    });
}

function onShippingContactSelected (e) {
    return postJson(action.onshippingcontactselected, filterEvent(e));
}

function onPaymentAuthorizedDwApplepay (e) {
    return postJson(action.onpaymentauthorized, filterEvent(e));
}

function onShippingMethodSelected (e) {
    return postJson(action.onshippingmethodselected, filterEvent(e));
}

function postJson (url, data) {
    var json = data;
    if (typeof data === 'object') {
        json = JSON.stringify(data);
    } else if (typeof data !== 'string') {
        throw new Error('Data must be an object or a JSON string.');
    }
    return fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: json
    }).then(handleResponse);
}

/** Filter out properties that are not from Event prototype,
 * and include all others as ownProperty to a new object.
 * @param {Event} e
 * @return {Object} object with relevant properties as ownProperty
 */
function filterEvent (e) {
    var filteredEvent = {};
    for (var prop in e) {
        /* global Event */
        if (!Event.prototype.hasOwnProperty(prop)) {
            filteredEvent[prop] = e[prop];
        }
    }
    return filteredEvent;
}

function handleResponse (response) {
    return response.json()
        .then(function (json) {
            if (response.status >= 200 && response.status < 300) {
                // Return success JSON response
                return json;
            }

            // Throw error with response status
            var err = new Error(json ? json.status : 'Request error');
            err.response = json;
            throw err;
        });
}

module.exports = {
    init,
    collectFraudData,
    initApplePay,
    createPaymentDataRequest,
    onValidateMerchant,
    onPaymentAuthorized,
    updateApplePayAmount,
    updateApplePayAddresses,
    onShippingContactSelected,
    onShippingMethodSelected,
    onPaymentAuthorizedDwApplepay
};
