'use strict';
var base = require('../product/base');
var cart = require('../cart/cart');

var pageHelpers = require('../helpers/pageHelpers');
var updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if (status == 'alert-success') {
        var oldWishlishlLength = $('.wishlist-quantity').html();
        oldWishlishlLength = parseInt(oldWishlishlLength, 10);
        $('.wishlist-quantity').html(oldWishlishlLength + 1);
    }

    var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
    var itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(function () {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}

/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

function hideMinicart(event) {
    if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
        || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
        || $('body').hasClass('modal-open')) {
        event.stopPropagation();
        return;
    }
    $('.minicart .popover').hide().removeClass('show');
}

module.exports = function () {
    cart();
    moveToWishlist();

    $('.minicart').on('count:update', function (event, count) {
        if (count) {
            if ($.isNumeric(count.quantityTotal)) {
                $('.minicart .minicart-quantity').text(count.quantityTotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems
                });
            } else if (count.basket && $.isNumeric(count.basket.numItems)) {
                $('.minicart .minicart-quantity').text(count.basket.numItems);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.basket.resources.minicartCountOfItems,
                    title: count.basket.resources.minicartCountOfItems
                });
            } else if ($.isNumeric(count.numItems)) {
                $('.minicart .minicart-quantity').text(count.numItems);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.resources.minicartCountOfItems,
                    title: count.resources.minicartCountOfItems
                });
            }
        }
    });

    $('.minicart').on('mouseenter focusin touchstart open', function () {
        if ($('.suggestions-wrapper').text() != '') {
            return;
        }
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                // Don't show minicart layout on mobile when we want to visit the cart page
                if (pageHelpers.isMobileDevice()) {
                    return true;
                }

                $('.minicart .popover').show().addClass('show');
                return;
            }

            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                $('.minicart .popover').show().addClass('show');
                updateMiniCart = false;
                $.spinner().stop();
                var applePayEnabledOnCart = Boolean($('.braintree-cart-apple-button').length > 0);

               if (applePayEnabledOnCart && window.ApplePaySession) {
                    var applePayBusinessLogic = require('../../../../../../int_braintree/cartridge/client/default/js/braintree/applepay/applePay');
                    applePayBusinessLogic.init();
                    applePayBusinessLogic.initCart();
                } else {
                    $('.braintree-cart-buttons-wrap').addClass('d-none');
                }
            });
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').hide().removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        hideMinicart(event);
    });
    $('.minicart').on('click', '.close-minicart', function (event) {
        hideMinicart(event);
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function (e, data) {
        updateMiniCart = true;

        if (data) {
            var reportingURL = null;

            if (data.basket) {
                reportingURL = data.basket.reportingURL;
            } else {
                reportingURL = data.reportingURL;
            }

            if (reportingURL) {
                base.methods.miniCartReportingUrl(reportingURL);
            }
        }
    });
};
