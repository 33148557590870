'use strict';

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
}

/**
 * Check if the input field is a new password field
 * @param {HTMLElement} inputEl - Input element to check
 * @returns {boolean} - True if the input field is a new password field, false otherwise
 */
function isFieldNewPassword(inputEl) {
    return inputEl.id === "newPassword" || inputEl.id === "newPasswordConfirm";
}

/**
 * Check if the input field is a regsistration password field
 * @param {HTMLElement} inputEl - Input element to check
 * @returns {boolean} - True if the input field is a new password field, false otherwise
 */
function isFieldRegistrationPassword(inputEl) {
    return inputEl.id === "registration-form-password";
}

/**
 * Get the password validation error message
 * @param {HTMLElement} inputEl - Input element to get the error message from
 * @returns {string} - The password validation error message
 */
function getPasswordValidationError(inputEl) {
    return [
        $(inputEl).data('start-error') || '',
        $(inputEl).data('range-error') || '',
        $(inputEl).data('pattern-mismatch') || '',
        $(inputEl).data('lowercase-error') || ''
    ].join("<br>-");
}

/**
 * Handles the validation of a password input element by checking for pattern and length mismatches.
 * If any validation errors are found, it updates the validation message accordingly.
 * 
 * @param {HTMLElement} inputEl - The input element to validate.
 */
function handlePasswordValidation(inputEl) {
    $(inputEl).addClass('is-invalid');
    var validationMessage = inputEl.validationMessage;
    var isPatternMismatch = inputEl.validity.patternMismatch;
    var isLengthMismatch = (inputEl.validity.tooLong || inputEl.validity.tooShort);
    var newPasswordEl = $(inputEl).parents("form").find("#newPassword")[0];
    
    if (isPatternMismatch || isLengthMismatch) {
        // If the element is either password or password confirm, use validation error from new password input
        validationMessage = getPasswordValidationError(newPasswordEl);
    }

    if (!$(newPasswordEl).hasClass('is-invalid')) {
        $(newPasswordEl).addClass('is-invalid');
    }

    $(newPasswordEl).parents('.form-group').find('.invalid-feedback')
        .html(validationMessage);
}

/**
 * Handle registration password validation for the input element
 * @param {HTMLElement} inputEl - Input element to validate
 * @returns {void}
 */
function handleRegistrationPasswordValidation(inputEl) {
    $(inputEl).addClass('is-invalid');
    var validationMessage = inputEl.validationMessage;
    var isValueMissing = inputEl.validity.valueMissing;
    var isPatternMismatch = inputEl.validity.patternMismatch;
    var isLengthMismatch = (inputEl.validity.tooLong || inputEl.validity.tooShort);

    if (isValueMissing) {
        validationMessage = $(inputEl).data("missing-error");
    } else if (isPatternMismatch || isLengthMismatch) {
        validationMessage = getPasswordValidationError(inputEl);
    }

    $(inputEl).parents('.form-group').find('.invalid-feedback')
        .html(validationMessage);
}

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                if (isFieldNewPassword(this)) {
                    handlePasswordValidation(this);
                    return;
                }
                
                if (isFieldRegistrationPassword(this)) {
                    handleRegistrationPasswordValidation(this);
                    return;
                }

                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.typeMismatch && $(this).data('type-mismatch')) {
                    validationMessage = $(this).data('type-mismatch');
                }
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },

    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};
