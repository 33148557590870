'use strict';

module.exports = {
    revealField: function () {
        $(document).on('click', '.js-reveal-field', function () {
            var field = $(this).closest('.custom-field').find('.custom-field__input');
            var currentType = field.attr('type');

            if (currentType === 'password') {
                $(this).addClass('password-eye-close').removeClass('password-eye-open');
                field.attr('type', 'text');
            } else {
                $(this).addClass('password-eye-open').removeClass('password-eye-close');
                field.attr('type', 'password');
            }
        });
    }
};
