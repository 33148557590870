'use strict';

/* global braintreeUtils braintree $ */

var btClientInstancePromise;

/**
 * Creates Apple Pay payment instance
 * @returns {Promise} A promise resolve with the Apple Pay instance
 */

function createApplePayPayment() {
    return btClientInstancePromise
        .then(function (btClientInstance) {
            return braintree.applePay.create({
                client: btClientInstance
            });
        });
}

/**
 * Creates a configuration object for creation an Apple Pay session
 * @param {applePayInstance} applePayInstance Apple Pay instance
 * @param {Object} applePayConfigs Apple Pay configs object
 * @param {number} amount Order total amount
 * @returns {Object} Configuration object
 */
function createPaymentDataRequest(applePayInstance, applePayConfigs, amount, billingContact, shippingContact) {
    return applePayInstance.createPaymentRequest({
        total: {
            label: applePayConfigs.options.displayName,
            amount: amount
        },
        billingContact: billingContact,
        shippingContact: shippingContact
    });
}

/**
 * Validates merchant website, as required by Apple Pay Session before payment can be authorized
 * @param {applePayInstance} applePayInstance  Apple Pay instance
 * @param {Object} sessionEvent Apple Pay session event
 * @param {Object} applePayConfigs Apple Pay configs object
 * @returns {Promise} Promise that resolve with 'merchantSession'
 */
function performValidation(applePayInstance, sessionEvent, applePayConfigs) {
    return applePayInstance.performValidation({
        validationURL: sessionEvent.validationURL,
        displayName: applePayConfigs.options.displayName
    });
}

/**
 * Tokenizes an Apple Pay payment
 * @param {applePayInstance} applePayInstance Apple pay instance
 * @param {Object} sessionEvent Apple pay session event
 * @returns {Promise} Promise that resolve with 'tokenizePayload'
 */
function tokenize(applePayInstance, sessionEvent) {
    return applePayInstance.tokenize({
        token: sessionEvent.payment.token
    });
}

/**
 * Inits braintreeApplePaySDK with client instance promise
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 */

function init(braintreeClientInstancePromise) {
    btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
    createApplePayPayment,
    createPaymentDataRequest,
    performValidation,
    tokenize,
    init
};
