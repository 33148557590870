'use strict';

var helper = require('../../helper');
var paymentMethodGeneral = require('../../paymentMethodGeneral');

var $applePayButton = document.querySelector('.js_braintree_applepay_button');

var paymentData;
var $braintreeCartButtons;
var applePayCheckoutFormData;
var errHandlingModel;

/**
 * Expends Apple Pay Checkout Form with required data
 */
function expandApplePayCheckoutFormData() {
    var csrfToken = document.querySelector('.braintree-cart-buttons-wrap  #csrf_token');

    applePayCheckoutFormData.append(csrfToken.name, csrfToken.value);
    applePayCheckoutFormData.append('braintreeApplePayNonce', paymentData.nonce);
    applePayCheckoutFormData.append('braintreeApplePayDeviceDataInput', paymentData.deviceData);
    applePayCheckoutFormData.append('braintreeApplePayShippingAddress',
        JSON.stringify(paymentData.shippingAddress) || '{}'
    );
}

/**
 * Process Apple Pay on the Cart Page
 */
function processCartFlow() {
    var billingAddressData = paymentData.billingAddress;
    var paymentMethodName = JSON.parse($applePayButton.getAttribute('data-braintree-config')).paymentMethodName;
    var placeOrderUrl = $braintreeCartButtons.data('checkout-placeorder-url');
    var checkoutFromCartUrl = $braintreeCartButtons.data('checkout-from-cart-url');
    var checkoutFormFields = $braintreeCartButtons.data('checkout-form-fields');
    var paymentFieldData = helper.getPaymentFieldsData(billingAddressData, paymentMethodName);
    if (paymentFieldData.stateCode === '') {
        paymentFieldData.stateCode = paymentFieldData.city.toLowerCase();
    }
    
    applePayCheckoutFormData = helper.createPaymentFormData(checkoutFormFields, paymentFieldData);
    expandApplePayCheckoutFormData();
    // submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set
    paymentMethodGeneral.submitCustomerForm(billingAddressData.email);

    var defer = $.Deferred();

    $.ajax({
        type: 'POST',
        url: checkoutFromCartUrl,
        data: applePayCheckoutFormData,
        contentType: false,
        processData: false,
        success: function (data) {
            if (data.error) {
                var errorMessage = '';

                if (data.fieldErrors.length) {
                    data.fieldErrors.forEach(function (error, index) {
                        var keys = Object.keys(error);

                        if (keys.length) {
                            errorMessage += `${keys[index].replace('dwfrm_billing_', '').replace('_', ' ')} ${data.fieldErrors[index][keys[index]]}. `;
                        }
                    });
                    errHandlingModel.applePayErrorHandler(errorMessage);
                }

                if (data.serverErrors.length) {
                    data.serverErrors.forEach(function (error) {
                        errorMessage += `${error}. `;
                    });
                    errHandlingModel.applePayErrorHandler(errorMessage);
                }

                if (data.cartError) {
                    window.location.href = data.redirectUrl;
                }

                return;
            } else {
                defer.resolve(data);
                $.ajax({
                    url: placeOrderUrl,
                    data: '',
                    method: 'POST',
                    success: function (data) {
                        if (data.error) {
                            if (data.cartError) {
                                window.location.href = data.redirectUrl;
                                defer.reject();
                            } else {
                                var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                                'fade show" role="alert">' +
                                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                                '<span aria-hidden="true">&times;</span>' +
                                '</button>' + data.errorMessage + '</div>';
                                $('.cart-error').append(errorHtml);
                                $('.cart-error').show();
                               
                                // if body page class does not have the cart-show class, then show the popover
                                // this is to prevent the popover from showing when the cart page is loaded
                                if (!$('body').hasClass('Cart-Show')) {
                                    $('.minicart .popover').show().addClass('show');
                                }
                                defer.reject();
                            }
                        } else {
                            var redirect = $('<form>')
                                .appendTo(document.body)
                                .attr({
                                    class: 'd-none',
                                    method: 'POST',
                                    action: data.continueUrl
                                });

                            $('<input>')
                                .appendTo(redirect)
                                .attr({
                                    name: 'orderID',
                                    value: data.orderID
                                });

                            $('<input>')
                                .appendTo(redirect)
                                .attr({
                                    name: 'orderToken',
                                    value: data.orderToken
                                });

                            redirect.submit();
                        }
                    },
                    error: function () {
                    
                    }
                });
            }
        },
        error: function (err) {
            if (err && err.redirectUrl) {
                window.location.href = err.redirectUrl;
            }
        }
    });
}

/**
 * Inits Apple Pay Cart components
 * @param {Object} data Apple Pay payment data
 * @param {Constructor} errorHandlingModel Error handling model
 */
function init(data, errorHandlingModel) {
    paymentData = data;
    $braintreeCartButtons = $('.braintree-cart-buttons-wrap');
    $applePayButton.setAttribute('data-is-inited', true);
    errHandlingModel = errorHandlingModel;

    processCartFlow();
}

module.exports = {
    init
};
