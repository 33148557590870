'use strict';

var helper = require('../../helper');

var $applePayButton = document.querySelector('.js_braintree_applepay_button');
var $applepayDataMethod = document.querySelector('.payment-options[role=tablist] .nav-item[data-method-id="DW_APPLE_PAY"]');
var $phoneField = document.querySelector('.contact-info-block [name=dwfrm_billing_contactInfoFields_phone]');

/**
 * Returns Braintree Apple Pay configs
 * @returns {Object} Braintree Apple Pay configs
 */
function getBraintreeApplePayConfigs() {
    var braintreeApplePayButtonConfigs = null;

    try {
        braintreeApplePayButtonConfigs = JSON.parse(document.querySelector('.js_braintree_applepay_button').getAttribute('data-braintree-config'));
    } catch (error) {
        return braintreeApplePayButtonConfigs;
    }

    return braintreeApplePayButtonConfigs;
}

/**
 * Sets device data from 'data collector' to corresponding input field
 * @param {Object} fraudDataPayload Froud payload data
 */

function setApplePayDeviceData(fraudDataPayload) {
    var $braintreeApplePayDeviceData = document.querySelector('input[name=braintreeApplePayDeviceDataInput]');

    if (fraudDataPayload.deviceData) {
        $braintreeApplePayDeviceData.value = fraudDataPayload.deviceData;
    }
}

/**
 * Sets shipping data from 'data collector' to corresponding input field
 * @param {Object} shippingAddressPayload Shipping Address payload data
 */

function setApplePayShippingAddress(shippingAddressPayload) {
    var $braintreeApplePayShippingAddress = $('input[name=braintreeApplePayShippingAddress]');

    if (shippingAddressPayload) {
        $braintreeApplePayShippingAddress.val(JSON.stringify(shippingAddressPayload));
        $braintreeApplePayShippingAddress.trigger('change');
    }
}

/**
 * Shows Apple Pay button
 */
function showApplePayButton() {
    $applePayButton.style.display = 'block';

    if ($applepayDataMethod) {
        $applepayDataMethod.style.display = 'block';
    }
}

/**
 * Hides Apple Pay tab on the Checkout/Billing page
 */
function hideApplePayTab() {
    $applePayButton.style.display = 'none';
    if ($applepayDataMethod) {
        $applepayDataMethod.style.display = 'none';
    }
}

/**
 * Marks Apple Pay button as disabled
 */
function makeApplePayButtonDisabled() {
    $applePayButton.classList.add('js_braintree_applepay_button_disabled');
}

/**
 * Remove session Apple Pay nonce
 */
function removeSessionNonce() {
    document.querySelector('#braintreeApplePayNonce').value = '';
    document.querySelector('#braintreeApplePayDeviceData').value = '';
}

/**
 * Validates required billing form fields
 * @returns {boolean} Whether billing form fields are valid
 */
function isBilligFiledsValid() {
    return helper.isValidInputField($phoneField);
}

/**
 * Depends of the 'data-paypal-is-hide-continue-button' hides or shows continue button on the Billing Page
 */
function toggleContinueButtonOnBillingPage() {
    var applePayContent = document.querySelector('.js_braintree_applepayContent');

    helper.continueButtonToggle(applePayContent.getAttribute('data-paypal-is-hide-continue-button'));
}

module.exports = {
    getBraintreeApplePayConfigs,
    setApplePayDeviceData,
    showApplePayButton,
    makeApplePayButtonDisabled,
    removeSessionNonce,
    isBilligFiledsValid,
    hideApplePayTab,
    toggleContinueButtonOnBillingPage,
    setApplePayShippingAddress
};
