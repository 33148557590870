'use strict';

/**
 * Activates wish list icon by checking if product is already added to wishlist.
 * @param {Array} jsonWishListItems - list of wish list product
 * @param {String} selectedPid - id of selected product
 */
function activateWLIcon(jsonWishListItems, selectedPid) {
    var itemExist = false;
    for (let i = 0; i < jsonWishListItems.length; i++) {
        var wlItem = jsonWishListItems[i];
        if (wlItem && wlItem.pid === selectedPid) {
            itemExist = true;
        }
    }
    return itemExist;
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, $icon) {
    var $iconParent = $icon.parent();
    var $addIcon = $iconParent.find('.js-add-to-wish-list-tile');
    var $removeIcon = $iconParent.find('.js-remove-from-wish-list-tile');

    $.spinner().stop();
    if (data.success) {
        var oldWishlishlLength = $('.wishlist-quantity').html();
        oldWishlishlLength = parseInt(oldWishlishlLength, 10);

        if ($addIcon.hasClass('d-none')) {
            $removeIcon.addClass('d-none');
            $addIcon.removeClass('d-none');
            $('.wishlist-quantity').html(oldWishlishlLength - 1);
        } else {
            $addIcon.addClass('d-none');
            $removeIcon.removeClass('d-none');
            $('.wishlist-quantity').html(oldWishlishlLength + 1);
        }
    }
}

const toggleVarProductWishList = (variationUrl, url, container, $icon, addProduct) => {
    variationUrl = new URL(variationUrl);
    variationUrl.searchParams.set('quantity', 1);

    $.ajax({
        url: variationUrl,
        method: 'GET',
        success: function (data) {
            var productId = data.product.id;
            toggleProductWishList(url, productId, container, $icon, addProduct);
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

const toggleProductWishList = (url, pid, $container, $icon, addProduct) => {
    var optionId = $container.find('.product-option').attr('data-option-id');
    var optionVal = $container.find('.options-select option:selected').attr('data-value-id');
    var type = addProduct ? 'post' : 'get';
    if (!url || !pid) {
        return;
    }

    $.ajax({
        url: url,
        type: type,
        dataType: 'json',
        data: {
            pid: pid,
            optionId: optionId || '',
            optionVal: optionVal || ''
        },
        success: function (data) {
            displayMessageAndChangeIcon(data, $icon);
            if (data && data.list && data.list.items) {
                $('input[name=jsonWishListItems]').val(JSON.stringify(data.list.items));
            }
        },
        error: function (err) {
            displayMessageAndChangeIcon(err, $icon);
        }
    });
};

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.js-add-to-wish-list-tile', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var pid = $(this).closest('.product').attr('data-pid');

            $.spinner().start();

            var $productContainer = $(this).closest('.product-tile');
            var $addToCartBtn = $productContainer.find('.js-add-to-cart-tile');
            var variationUrl = $addToCartBtn.attr('data-variationUrl');
            if (variationUrl) {
                toggleVarProductWishList(variationUrl, url, $productContainer, $(this), true);
            } else {
                toggleProductWishList(url, pid, $productContainer, $(this), true);
            }
        });
    },
    removeFromWishlist: function () {
        $('body').on('click', '.js-remove-from-wish-list-tile', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var pid = $(this).closest('.product').attr('data-pid');

            $.spinner().start();

            var $productContainer = $(this).closest('.product-tile');
            var $addToCartBtn = $productContainer.find('.js-add-to-cart-tile');
            var variationUrl = $addToCartBtn.attr('data-variationUrl');

            if (variationUrl) {
                toggleVarProductWishList(variationUrl, url, $productContainer, $(this), false);
            } else {
                toggleProductWishList(url, pid, $productContainer, $(this), false);
            }
        });
    },
    toggleWLIcon: function () {
        $('body').on('click', '.js-plp-size-button', function (e) {
            var $button = $(e.currentTarget);
            var jsonWishListItems = $('input[name=jsonWishListItems]').val();
            var sizeSelected = $(this).attr('data-pid');

            jsonWishListItems = JSON.parse(jsonWishListItems);
            var activateIcon = activateWLIcon(jsonWishListItems, sizeSelected);

            var $productContainer = $button.closest('.product-tile');
            $productContainer.find('.js-add-to-wish-list-tile').toggleClass('d-none', activateIcon);
            $productContainer.find('.js-remove-from-wish-list-tile').toggleClass('d-none', !activateIcon);
        });
    },
    toggleWishlistIconOnload: function () {
        $(window).on('load wishlistIcon', function () {
            if ($('input[name=jsonWishListItems]').length > 0) {
                var jsonWishListItems = $('input[name=jsonWishListItems]').val();

                if (jsonWishListItems !== 'null') {
                    var $productTile = $('.product-tile');
                    var $productDetail = $('.product-detail');

                    if ($productDetail.length) {
                        $productTile.push($productDetail);
                    }

                    jsonWishListItems = JSON.parse(jsonWishListItems);
                    for (var i = 0; i < $productTile.length; i++) {
                        var productId = $($productTile[i]).attr('data-pid');
                        var activateIcon = activateWLIcon(jsonWishListItems, productId);
                        $($productTile[i]).find('.js-add-to-wish-list-tile, .add-to-wish-list').toggleClass('d-none', activateIcon);
                        $($productTile[i]).find('.js-remove-from-wish-list-tile, .remove-from-wish-list').toggleClass('d-none', !activateIcon);
                    }
                }
            }

        });
    }
};
