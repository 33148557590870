'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.js-l1-item:not(.disabled)')
        .on('mouseover', function (e) {
            if (isDesktop(this)) {
                if (!$(this).hasClass('show') && $(this).find('.l3-item').length > 0) {
                    $('.js-l2-menu', $(this)).addClass('show');
                }
                $('.navbar-header .user .popover').removeClass('show');
            } else {
                e.preventDefault();
            }
        })
        .on('mouseout', function (e) {
            if (isDesktop(this)) {
                if ($('.js-l2-menu', $(this).hasClass('show'))) {
                    $('.js-l2-menu', $(this)).removeClass('show');
                }
            } else {
                e.preventDefault();
            }
        })
        .on('click', function (e) {
            if (!isDesktop(this) && $(e.target).parent('.js-l1-item').length) {
                e.preventDefault();

                if ($(this).find('.l3-item').length == 0) {
                    window.location.href = $(this).find('.nav-link').attr('href');
                } else {
                    $(e.target).parent().find('.mobile-menu-slide').addClass('show');
                    $('.js-no-burger').addClass('d-none').removeClass('d-block');
                } 
            } else if (isDesktop(this) && $(e.target).hasClass('l2-link-item')) {
                e.preventDefault();
            }
        });

    $('body').on('click', '.close-button', function (e) {
        e.preventDefault();

        $('.main-menu').removeClass('show');
        $('.js-no-search').addClass('d-none');
        $('.js-no-burger').addClass('col-auto');

        $('.modal-background').hide();
    });

    $('.navbar-toggler').change(function () {
        var action = $(this).attr('data-action');

        if (action == 'open') {
            $('.js-no-burger').removeClass('col-auto');

            $('.main-menu').toggleClass('in');
            $('.modal-background').show();

            $('.main-menu').addClass('show');
            $('.main-menu').removeClass('d-none');
            $('.main-menu').attr('aria-hidden', 'false');
            $('.main-menu').siblings().attr('aria-hidden', 'true');
            $('header').siblings().attr('aria-hidden', 'true');

            $('.main-menu .nav.navbar-nav .nav-link').first().focus();

            $(this).attr('data-action', 'close');
            $('body').addClass('no-scroll');
        } else if (action == 'close') {
            $('.main-menu').removeClass('show');
            $('.js-no-search').addClass('d-none');
            $('.js-no-burger').addClass('col-auto');

            $(this).attr('data-action', 'open');
            $('.modal-background').hide();
            $('body').removeClass('no-scroll');
        }
    });

    $('.js-navigation-close').click(function (e) {
        e.preventDefault();
        $('.mobile-menu-slide').removeClass('show');
        $('.js-no-burger').addClass('d-block').removeClass('d-none');
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('click', function () {
        var $userHeader = $('.navbar-header .user .popover');
        if ($userHeader.length > 0 && !$userHeader.hasClass('show')) {
            $userHeader.addClass('show');
            $('.user').attr('aria-expanded', 'true');
        } else {
            $userHeader.removeClass('show');
            $('.user').attr('aria-expanded', 'false');
        }
    });

    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });
};
