'use strict';
/**
 * @function getCookie
 *
 * @param {string} key - The cookie name
 * @returns {string} value - the value of the cookie if found, null otherwise
 **/
function getCookie(key) {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var tokens = cookies[i].split('=');
        var cookieKey = tokens[0].trim();
        if (cookieKey === key) {
            return tokens[1];
        }
    }
    return '';
}

/**
 * @function setCookie
 *
 * @param {string} key - The cookie name
 * @param {string} value - The cookie value
 * @param {integer} minutes - The number of minutes to expire the cookie
 **/
function setCookie(key, value, minutes) {
    var date = new Date();
    date.setTime(date + (minutes * 60 * 1000));

    document.cookie = key + '=' + value + '; expires=' + date.toGMTString() + '; path=/';
}

/**
 * @function isMobileDevice
 * 
 * @returns {boolean} value - the value true if the device is mobile
 **/
function isMobileDevice() {
    // condition to check if device is tablet IOS 
    let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1 && window.innerWidth <= 991;

    if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.innerWidth <= 991 || isIpad) {
        return true;
    }
    return false;
}

exports.setCookie = setCookie;
exports.getCookie = getCookie;
exports.isMobileDevice = isMobileDevice;
