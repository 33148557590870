'use strict';

module.exports = {
    init: function () {
        // Enter the times for the timed banner to start and end below
        var cContainer = $('.countdown');
    
        var cStartTime = cContainer.attr("data-start-time"),
            cEndTime = cContainer.attr("data-end-time");
    
        if ($('.countdownTimer').length) {
            var pad = function(n, len) { // leading 0's
                var s = n.toString();
                return (new Array((len - s.length + 1)).join('0')) + s;
            };
    
            if (cEndTime < 0 || cEndTime > 23 || cStartTime < 0 || cStartTime >= 23) {
                console.warn('Time for countdown timer should be set between 0 and 23');
            }
    
            var timeNow = new Date();
            var nextDay = new Date(timeNow);
            nextDay.setDate(nextDay.getDate() + 1);
            nextDay = nextDay.toDateString().split(' ');
            nextDay = nextDay[1] + ' ' + nextDay[2];
    
            if ((timeNow.getHours() < cEndTime) && (timeNow.getHours() >= cStartTime)) {
                changeToTimer();
            }
    
            if (cEndTime > cStartTime && cEndTime <= 23 && cStartTime >= 0) {
                window.setInterval(
                    function countDown() {
                        var now = new Date(),
                            hrs,
                            mins,
                            str,
                            target;
    
                        if (now.getDay() >= 0) {
                            target = cEndTime; // 12:00 hrs is default cut-off point
    
                            if (now.getHours() == cStartTime) {
                                changeToTimer();
                            }
    
                            if ((now.getHours() < target) && (now.getHours() >= cStartTime)) { // don't do anything if we're past the cut-off point
                                hrs = (target - 1) - now.getHours();
                                if (hrs < 0) hrs = 0;
                                mins = 59 - now.getMinutes();
                                if (mins === 0 || mins < 0) {
                                    cContainer.hide();
                                }
                                str = pad(hrs, 1) + ' hrs ' + pad(mins, 1) + ' mins ';
                                $('.countdownTimer').html(str);
                                $('.nextDay').html(nextDay);
                            } 
                        } else {
                            cContainer.hide();
                        }
                    }, 1000
                );
            }
        }
        /**
         * Show timer
         */
        function changeToTimer() {
            cContainer.fadeIn('slow');
        }
    }
};