'use strict';

/* global braintreeUtils braintree $ ApplePaySession */

// Helpers
var loaderInstance = require('../../loaderHelper');
var applePayHelper = require('../helpers/applePayHelper');
var applePayPaymentProcessingHelper = require('../helpers/applePayPaymentProcessingHelper');
var helper = require('../../helper');

var applePayPaymentProcessingConstants = require('../constants/applePayPaymentProcessingConstants');

// Components
var applePayCart = require('./applePayCart');
var applePayCheckout = require('./applePayCheckout');

var $braintreeApplePayLoader = document.querySelector('.braintreeApplePayLoader');
var $applePayButton = $('.js_braintree_applepay_button');
var $continueButton = document.querySelector('button.submit-payment');
var applePayEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_applepayContent'));

// global variables
var loader;
var applePayModel;
var applePayConfigs;
var errHandlingModel;
/** Latest Apple Pay request data to use to create a session. */
var request;
/** Request object that gets updated with new data from server responses. */
var updatedRequest;
// set updatedRequest to request
updatedRequest = Object.assign({}, request);



var STATUSES = null;

/**
 * Functionality over tokenized payment
 * @param {Object} paymentData Apple Pay payment data
 */
function onTokenizedPayment(paymentData) {
    var applePayFlow = applePayPaymentProcessingHelper.getApplePayFlowId();

    helper.removeActiveSessionPayment();

    switch (applePayFlow) {
        case applePayPaymentProcessingConstants.FLOW_CHECKOUT_NAME:
            applePayCheckout.init(paymentData);
            $continueButton.click();
            break;
        case applePayPaymentProcessingConstants.FLOW__CART_NAME:
            applePayCart.init(paymentData, errHandlingModel);
            break;
        default:
            break;
    }
}
/**
 * Creates Apple Pay session. Opens and processes Apple Pay payment window.
 */
function createApplePaySession() {
    var applePayFlow = applePayPaymentProcessingHelper.getApplePayFlowId();
    applePayConfigs = JSON.parse($applePayButton.attr('data-braintree-config'));
    // Updates order amount for paymentDataRequest
    applePayModel.updateApplePayAmount(applePayConfigs);

    // Updates order shipping and billing addresses for paymentDataRequest
    if (applePayFlow !== 'cart') {
        applePayModel.updateApplePayAddresses(applePayConfigs);
    }

    // Creates a configuration object for creating Apple pay session
    var paymentDataRequest = applePayModel.createPaymentDataRequest(applePayConfigs);

    if (applePayConfigs.isRequiredBillingContactFields) {
        paymentDataRequest.requiredBillingContactFields = ['postalAddress', 'name'];
        if (applePayFlow === 'cart') {
            paymentDataRequest.requiredShippingContactFields = ['phone', 'email'];
        }
    }

    if (applePayConfigs.isRequiredShippingContactFields) {
        paymentDataRequest.requiredShippingContactFields = ['postalAddress', 'name','phone', 'email'];
    }

    /** Map of Demandware status names to Apple Pay status codes */
    STATUSES = {
        Failure: ApplePaySession.STATUS_FAILURE,
        InvalidBillingPostalAddress: ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS,
        InvalidShippingPostalAddress: ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS,
        InvalidShippingContact: ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT,
        PINRequired: ApplePaySession.STATUS_PIN_REQUIRED,
        PINIncorrect: ApplePaySession.STATUS_PIN_INCORRECT,
        PINLockout: ApplePaySession.STATUS_PIN_LOCKOUT
    };

    // Creates new Apple Pay session
    var session = new ApplePaySession(3, paymentDataRequest);

    // Validates merchant website
    session.onvalidatemerchant = function (event) {
        $('.payment-information').attr('data-apple-pay-loaded', true);
        applePayModel.onValidateMerchant(event, applePayConfigs)
            .then(function (merchantSession) {
                session.completeMerchantValidation(merchantSession);
            })
            .catch(function (error) {
                errHandlingModel.applePayErrorHandler(error);
                session.abort();
            });
    };

    // Tokenizes an Apple Pay payment
    session.onpaymentauthorized = function (event) {
        loader.show();

        applePayModel.onPaymentAuthorized(event)
            .then(function (tokenizedPayload) {
                var validateAddressUrl = applePayConfigs.validateAddressUrl;
                var TokenizedPaymentData = applePayPaymentProcessingHelper.getTokenizedPaymentData(event, tokenizedPayload);
                            
                var billingForm = new FormData();
                billingForm.append("dwfrm_billing_addressFields_address1", TokenizedPaymentData.billingAddress.streetAddress);
                billingForm.append("dwfrm_billing_addressFields_address2", TokenizedPaymentData.billingAddress.extendedAddress ? TokenizedPaymentData.billingAddress.extendedAddress : "");
                billingForm.append("dwfrm_billing_addressFields_states_stateCode", TokenizedPaymentData.billingAddress.locality);
                billingForm.append("dwfrm_billing_addressFields_country", TokenizedPaymentData.billingAddress.countryCodeAlpha2);
                billingForm.append("dwfrm_billing_addressFields_city", TokenizedPaymentData.billingAddress.locality);
                billingForm.append("dwfrm_billing_addressFields_postalCode", TokenizedPaymentData.billingAddress.postalCode);
                
                $.ajax({
                    url: validateAddressUrl,
                    type: 'post',
                    data: billingForm,
                    processData: false,
                    contentType: false,
                    success: function (data) {
                        if(data.error) {
                            session.completePayment(ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS);
                        } else {
                            var shippingAddress = TokenizedPaymentData.shippingAddress;

                            if (data.shippingMethodId !== 'pickup-in-store' && shippingAddress && (!shippingAddress.firstName.trim() || !shippingAddress.lastName.trim() || !shippingAddress.streetAddress.trim() || !shippingAddress.locality.trim() || !shippingAddress.postalCode.trim())) {
                                session.completePayment(ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS);
                            } else {
                                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                                if(data.shippingMethodId !== 'pickup-in-store') {
                                    applePayHelper.setApplePayShippingAddress(TokenizedPaymentData.shippingAddress ? TokenizedPaymentData.shippingAddress : TokenizedPaymentData.billingAddress);
                                }
                                onTokenizedPayment(TokenizedPaymentData);
                            }
                        }
                    }
                });
                
                loader.hide();
            })
            .catch(function (error) {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                loader.hide();
                errHandlingModel.applePayErrorHandler(error);
            });
    };

    session.onshippingcontactselected = function (event) {
        loader.show();

        applePayModel.onShippingContactSelected(event)
            .then(function (response) {
                updatedRequest = Object.assign(updatedRequest, response);
                if (!event.shippingContact.countryCode.trim() || !event.shippingContact.locality.trim() || !event.shippingContact.administrativeArea.trim() || !event.shippingContact.postalCode.trim()) {
                    session.completeShippingContactSelection(ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS,
                        response.shippingMethods, response.total, response.lineItems);
                } else {    
                    if (response.shippingMethods.length > 0) {
                        session.completeShippingContactSelection(ApplePaySession.STATUS_SUCCESS,
                            response.shippingMethods, response.total, response.lineItems);
                    } else {
                        session.completeShippingContactSelection(ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS,
                            response.shippingMethods, response.total, response.lineItems);
                    }
                }
                loader.hide();
            })
            .catch(function (error) {
                session.completeShippingContactSelection(mapStatus(error.message),
                [], updatedRequest.total, updatedRequest.lineItems);
                loader.hide();
                errHandlingModel.applePayErrorHandler(error);
            });
    };

    session.oncancel = function (event) {
        $('.checkout-btn').removeClass('disabled');
    };

    session.begin();
}

/**
 * Call back function to be resolved when Apple Pay payment button is clicked
 */
function onApplePayButtonClick() {
    errHandlingModel.hideError();

    // Billing flow
    if (applePayEnabledOnCheckout) {
        // Validates email and phone fields on the Billing page
        if (applePayHelper.isBilligFiledsValid()) {
            createApplePaySession();
        }
    // Cart flow
    } else {
        createApplePaySession();
    }
}

/**
 * Init and add Apple Pay on the Cart or Billing page
 */
function initApplePay() {
    loader.show();

    var result = applePayModel.collectFraudData()
        .then(function (fraudDataPayload) {
            applePayHelper.setApplePayDeviceData(fraudDataPayload);
            loader.hide();

            return applePayModel.initApplePay(ApplePaySession)
                .then(function (canMakePayments) {
                    loader.hide();
                    applePayHelper.showApplePayButton();

                    if (!canMakePayments) {
                        applePayHelper.makeApplePayButtonDisabled();
                    } else {
                        $('body').on('click', '.js_braintree_applepay_button', onApplePayButtonClick);
                    }
                });
        });
    result
        .catch(function (error) {
            loader.hide();
            errHandlingModel.applePayErrorHandler(error);
        });
}

/**
 * Inits Apple Pay payment processing components
 * @param {Object} braintreeApplePayModel Apple Pay model
 * @param {Constructor} errorHandlingModel Error handling model
 */
function init(braintreeApplePayModel, errorHandlingModel) {
    loader = loaderInstance($braintreeApplePayLoader);
    applePayModel = braintreeApplePayModel;
    applePayConfigs = JSON.parse($applePayButton.attr('data-braintree-config'));
    errHandlingModel = errorHandlingModel;

    if (window.ApplePaySession) {
        initApplePay();
    } else {
        applePayHelper.hideApplePayTab();
    }
}

function mapStatus (status) {
    if (status && STATUSES[status]) {
        return STATUSES[status];
    }
    return ApplePaySession.STATUS_FAILURE;
}

module.exports = {
    init
};
