window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./v3.js'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./components/baseElementsHelpers'));
    processInclude(require('./components/tile.js'));
    processInclude(require('./components/v12Tile.js'));
    processInclude(require('./product/wishlistHeart.js'));
    processInclude(require('./product/countdown.js'));
    processInclude(require('./product/buttonRippleEffect'));
});

require('./components/carousels');
require('./thirdParty/bootstrap');
require('./components/spinner');
require('./helpers/lazyload');
require('jquery-ui/ui/widgets/tabs');
