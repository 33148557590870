'use strict';
// This file is about form fields manipulations.

/**
 * Returns an object contains an object and an array of credit card fields to Display
 * @returns {Object} Object
 */
function getCCFieldsToDisplay() {
    var $cardOwnerToDisplay = document.querySelector('#braintreeCardOwnerToDisplay');
    var $cardNumberToDisplay = document.querySelector('#braintreeCardNumberToDisplay');
    var $cardCvvToDisplay = document.querySelector('#braintreeCvvToDisplay');
    var $cardExpirationToDisplay = document.querySelector('#braintreeExpirationToDisplay');

    var ccToDisplayFieldsAsObject = {
        cardOwnerToDisplay: $cardOwnerToDisplay,
        cardNumberToDisplay: $cardNumberToDisplay,
        cardCvvToDisplay: $cardCvvToDisplay,
        cardExpirationToDisplay: $cardExpirationToDisplay
    };
    var ccToDisplayFieldsAsArray = Object.values(ccToDisplayFieldsAsObject);

    return {
        asObject: ccToDisplayFieldsAsObject,
        asArray: ccToDisplayFieldsAsArray
    };
}

/**
 * Returns an object contains an object and an array of credit card fields
 * @returns {Object} Object
 */
function getCCFields() {
    var $braintreeCardOwner = document.querySelector('#braintreeCardOwner');
    var $braintreeExpirationDate = document.querySelector('#braintreeExpirationDate');
    var $braintreeCardNumber = document.querySelector('#braintreeCardNumber');
    var $braintreeCvv = document.querySelector('#braintreeCvv');

    var ccFieldsAsObject = {
        braintreeCardOwner: $braintreeCardOwner,
        braintreeExpirationDate: $braintreeExpirationDate,
        braintreeCardNumber: $braintreeCardNumber,
        braintreeCvv: $braintreeCvv
    };
    var ccFieldsAsArray = Object.values(ccFieldsAsObject);

    return {
        asObject: ccFieldsAsObject,
        asArray: ccFieldsAsArray
    };
}

/**
 * Returns braintreeCreditCardNonce container
 * @returns {Object} Input element
 */
function getCreditCardNonceFieldContainer() {
    return document.querySelector('#braintreeCreditCardNonce');
}

/**
 * Returns braintreeCreditCardList container
 * @returns {Object} Select element
 */
function getCreditCardListContainer() {
    return document.querySelector('#braintreeCreditCardList');
}

/**
 * Sets Credit Card field to display
 * @param {Object} selectedCard The values of fields to be set
 * @param {Object} cacheCardFields Object of elements contain credit cards fields to display
 */
function setCreditCardFieldsToDisplay(selectedCard, cacheCardFields) {
    var cartNumber = selectedCard.dataset.number;

    cacheCardFields.cardNumberToDisplay.innerHTML = cartNumber;
    cacheCardFields.cardCvvToDisplay.innerHTML = '***';
    cacheCardFields.cardExpirationToDisplay.innerHTML = selectedCard.dataset.expiration;
    cacheCardFields.cardOwnerToDisplay.innerHTML = selectedCard.dataset.owner;

    document.querySelector('#braintreeCardType').value = selectedCard.dataset.type;
    document.querySelector('#braintreeCardMaskNumber').value = cartNumber;
}

/**
 * Sets Credit Card fileds on the Billing Page
 * @param {Object} selectedCard The values of fields to be set
 * @param {Object} cacheCardFields Object of elements contain credit cards fields
 */
function setCardFields(selectedCard, cacheCardFields) {
    cacheCardFields.cardNumberToDisplay.innerHTML = selectedCard['data-number'].value;
    cacheCardFields.cardCvvToDisplay.innerHTML = '***';
    cacheCardFields.cardExpirationToDisplay.innerHTML = selectedCard['data-expiration'].value;
    cacheCardFields.cardOwnerToDisplay.innerHTML = selectedCard['data-owner'].value;

    document.querySelector('#braintreeCardType').value = selectedCard['data-type'].value;
    document.querySelector('#braintreeCardMaskNumber').value = selectedCard['data-number'].value;
}

/**
 * Shows Credit Card fileds on the Billing Page
 * @param {Array} cardFields Array of credit card fileds
 */
function showCardElements(cardFields) {
    cardFields.forEach(function (el) {
        el.style.display = '';
    });
}

/**
 * Hides Credit Card fileds on the Billing Page
 * @param {Array} cardFields Array of Credit Card fileds
 */
function hideCardElements(cardFields) {
    cardFields.forEach(function (el) {
        el.style.display = 'none';
    });
}

/**
 * Returns Credit Card hosted fileds styles
 * @returns {Object} Object of styles
 */
function getHostedFieldsStyles() {
    return {
        input: {
            'font-size': 'inherit',
            color: '#b7802a',
            padding: '6px 15px'
        },
        ':focus': {
            color: 'blue'
        },
        '.valid': {
            color: 'green'
        },
        '.cardholderName.valid': {
            color: 'black'
        },
        '.invalid': {
            color: 'red'
        }
    };
}

/**
 * Returns Credit Card hosted fields configs
 * @returns {Object} Object of configs
 */
function getHostedFieldsConfig() {
    return {
        cardholderName: {
            selector: '#braintreeCardOwner'
        },
        number: {
            selector: '#braintreeCardNumber'
        },
        cvv: {
            selector: '#braintreeCvv'
        },
        expirationDate: {
            selector: '#braintreeExpirationDate'
        }
    };
}

module.exports = {
    getCCFieldsToDisplay,
    getCCFields,
    getCreditCardNonceFieldContainer,
    getCreditCardListContainer,
    showCardElements,
    hideCardElements,
    setCreditCardFieldsToDisplay,
    setCardFields,
    getHostedFieldsStyles,
    getHostedFieldsConfig
};
