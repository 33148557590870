(function () {
    var recaptchaKey = document.getElementById('recaptchaKey'); // Google Recaptcha Key
    if (recaptchaKey) {
        recaptchaKey = recaptchaKey.value;
    }
    var reloadTime = 20000; // Reload automatically recaptchas every X milliseconds

    if (!recaptchaKey) {
        return;
    }

    /**
     * Creates a recaptcha hidden input
     */
    function createInputResponse(token) {
        var input = document.createElement('input');
        input.value = token;
        input.type = 'hidden';
        input.className = 'captcha-response';
        input.name = 'g-recaptcha-response';

        return input;
    }
    /**
     * Check if there is any recaptcha input file in current form
     */
    function checkInputExist(form) {
        return form.contains(document.getElementsByClassName('captcha-response')[0]);
    }

    /**
     * Place an input hidden with recaptcha token in all recaptchas found on current page.
     */
    grecaptcha.ready(function () {// eslint-disable-line
        grecaptcha.execute(recaptchaKey, { action: 'contactus' }).then(function (token) {// eslint-disable-line
            var forms = document.getElementsByTagName('form');

            for (var i = 0; i < forms.length; i++) {

                // Ignore forms that contains the attribute "recaptcha-ignore"
                var ignoreRecaptcha = forms[i].getAttribute('recaptcha-ignore');
                if (!checkInputExist(forms[i]) && !ignoreRecaptcha) {
                    forms[i].appendChild(createInputResponse(token));
                }
            }

            // enable form submit buttons as recaptcha is loaded
            $('.disabled-until-recaptcha-load').removeAttr('disabled');
        });
    });

    /**
     * Reloads all recaptcha inputs found on current page
     */
    window.reloadRecaptcha = function () {
        grecaptcha.execute(recaptchaKey, { action: 'contactus' }).then(function (token) {// eslint-disable-line
            var captchas = document.getElementsByClassName('captcha-response');
            for (var i = 0; i < captchas.length; i++) {
                captchas[i].value = token;
            }
        });
    };

    /**
     * Adds a recaptcha input to specified form with className
     */
    window.addRecaptcha = function (className, action) {
        grecaptcha.execute(recaptchaKey, { action: action }).then(function (token) {// eslint-disable-line
            var forms = document.getElementsByClassName(className);

            for (var i = 0; i < forms.length; i++) {
                // Ignore forms that contains the attribute "recaptcha-ignore"
                var ignoreRecaptcha = forms[i].getAttribute('recaptcha-ignore');
                if (!checkInputExist(forms[i]) && !ignoreRecaptcha) {
                    forms[i].appendChild(createInputResponse(token));
                }
            }
        });
    };

    /**
     * Reloads all recaptchas automatically every {reloadTime} milliseconds
     */
    setInterval(function () {
        window.reloadRecaptcha();
    }, reloadTime);
})();
