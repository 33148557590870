'use strict';

/**
 * Hide PayPal button
 */
function hidePayPalButtons() {
    var payPalButtonsContainer = document.querySelector('.js_braintree_paypal_billing_button');

    payPalButtonsContainer.style.display = 'none';
}

/* -------------------------------------------- Account\Options Manipulations -------------------------------------------- */

/**
 * Hide account option from account dropdown
 * @param {Object} $accountOption vanilla js account option container
 */
function hideAccount($accountOption) {
    $accountOption.style.display = 'none';
}

/**
 * Hide Save New Account checkbox with corresponding block
 */
function hideSaveNewAccountContainer() {
    var savePaypalAccountCheckbox = document.getElementById('braintreeSavePaypalAccount');
    var savePaypalAccountContainer = document.querySelector('#braintreeSavePaypalAccountContainer');

    savePaypalAccountCheckbox.value = false;
    savePaypalAccountContainer.style.display = 'none';
}

/**
 * Make all hidden stored paypal accounts visible for buyer
 */
function displayAllStoredAccounts() {
    var braintreePaypalAccountsList = document.querySelector('#braintreePaypalAccountsList');

    Array.prototype.forEach.call(braintreePaypalAccountsList, function (el) {
        if (el.style.display === 'none') {
            el.style.display = 'block';
        }
    });
}

/**
 * Hides 'Save PayPal Account' checkbox on the Billing Page
 */
function hideSavePayPalAccountBlock() {
    var $savePaypalAccountCountainer = document.querySelector('#braintreeSavePaypalAccountContainer');

    $savePaypalAccountCountainer.style.display = 'none';
}

/**
 * Function which store billing data on session payment option level
 * @param {Object} btPayloadDetails braintree payload details
 */
function addBillingAddressOnPaymentOptionLevel(btPayloadDetails) {
    var $sessionPyapalAccount = document.querySelector('#braintreePaypalAccount');
    var btBillingAddress = btPayloadDetails.shippingAddress;

    $sessionPyapalAccount.setAttribute('data-billing-address', JSON.stringify({
        firstName: btPayloadDetails.firstName,
        lastName: btPayloadDetails.lastName,
        line1: btBillingAddress.line1,
        city: btBillingAddress.city,
        state: btBillingAddress.state,
        postalCode: btBillingAddress.postalCode
    }));
}

module.exports = {
    hidePayPalButtons,
    // Account\Options Manipulations
    hideAccount,
    hideSaveNewAccountContainer,
    displayAllStoredAccounts,
    hideSavePayPalAccountBlock,
    addBillingAddressOnPaymentOptionLevel
};
