'use strict';

/**
 * Adds a MutationObserver to a target node and performs an action when a childList mutation occurs
 * @param {Node} targetNode - the target node to observe
 * @param {Function} actionToPerform - the action to perform when a childList mutation occurs
 * @returns {void}
 */
function addMutationObserverAction(targetNode, actionToPerform) {
    // observer options
    const config = {
        attributes: true,
        childList: true,
        subtree: true
    };

    // callback function for observer
    /**
     * Callback function for observing mutations in a DOM element
     * @param {Array} mutationsList - List of mutations observed
     * @param {MutationObserver} observer - The MutationObserver object
     */
    const callback = function (mutationsList, observer) {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                // action to perform
                actionToPerform();

                // disconnect observer
                observer.disconnect();
            }
        }
    };

    // create observer instance and observe
    const mutationObserver = new MutationObserver(callback);
    mutationObserver.observe(targetNode, config);
}

/**
 * Updates the product tiles with V12 finance information
 */
function updateProductTiles() {
    const $productTiles = $('.product-tile');

    for (let i = 0; i < $productTiles.length; i++) {
        let currentTile = $productTiles[i];
        let v12eligibility = $(currentTile).attr('data-v12eligibility');
        let v12productfilterterms = JSON.parse($(currentTile).attr('data-productfilterterms'));
        if (v12productfilterterms && v12productfilterterms.length > 0) {
            let productId = v12productfilterterms[v12productfilterterms.length-1] ? v12productfilterterms[v12productfilterterms.length-1].productId : v12productfilterterms[0].productId;
            let financeProduct = V12.getFinanceProduct(productId); // eslint-disable-line
            if (financeProduct) {
                if (v12eligibility == 'true') {
                    let cashPrice = $(currentTile).attr('data-cashprice');

                    if (cashPrice != 'null') {
                        let depositFactor = $(currentTile).attr('data-depositfactor');
                        let deposit = parseInt(Math.ceil(cashPrice * depositFactor), 10) / 100;
                        deposit = parseFloat(deposit.toFixed(2));

                        let payments = V12.calculate(financeProduct, cashPrice, deposit); // eslint-disable-line
                        let v12Amount = $(currentTile).find('.v12-amount').text();
                        let newV12Money = v12Amount.replace('0.00', payments.initialPayments);
                        $(currentTile).find('.v12-amount').text(newV12Money);
                    }
                }
            }
        }
    }
}

/**
 * Updates the V12 tile
 * @returns {void}
 */
function updateTileV12() {
    if (typeof V12 !== 'undefined' && V12) { // eslint-disable-line
        let products = V12.getFinanceProducts(); // eslint-disable-line
        if (products && products.length > 0) {
            const $productTiles = $('.product-tile');
            const $tileV12Recommender = $('[id*=cq_recomm_slot]');

            if ($productTiles.length) {
                updateProductTiles();
            }

            if ($tileV12Recommender.length && $tileV12Recommender.is(':empty')) {
                addMutationObserverAction($tileV12Recommender[0], updateProductTiles);
            }
        }
    }
}

module.exports = {
    updateTileV12: updateTileV12
};
